<template>
  <div class="panel-toolbar html2canvas-ignore">
    <form class="" data-html2canvas-ignore>
      <div class="form-group" style="position: relative">
        <div>
          <DatetimeRangePicker
            v-if="contract && showDateTimePicker"
            :startRef="dtIni"
            :endRef="dtEnd"
            :min="maxInterval"
            :uiConfig="toolbar.calendarUIConfig"
            :shortcuts="toolbar.calendarShortCuts"
            :allowFutureDate="false"
            :showRefreshIcon="endsNow"
            :isHistory="true"
            @interval-changed="onIntervalChanged"
            @dateRangePickerEvent="onDateRangePickerEvent"
          />
        </div>
      </div>
      <div class="tab-toolbar-control" v-if="dropdownOptions.length">
        <dropdown
          position="right"
          :data="dropdownOptions"
          :multiple="true"
          :showAll="true"
          :title="dropDownHint"
          @apply="dropdownApply"
          @changed="onFilterChanged"
          :class="
            selectedFilterLabels.length
              ? 'btn-primary btn-filter'
              : 'btn-filter'
          "
        >
          <span v-if="dropDownText">
            <i class="fa fa-filter toolbar-btn-icon"></i>
            <span style="margin-left: 5px">
              {{ dropDownText }}
            </span>
          </span>
          <span v-else>
            <i class="fa fa-filter toolbar-btn-icon"></i>
          </span>
        </dropdown>
      </div>
      <template v-if="media != 'print' && toolbar.downloadButton">
        <DownloadButton
          @ready="onDownloading(true)"
          @done="onDownloading(false)"
          :downloading="downloading"
          :xls="toolbar.downloadXLS"
          :csv="toolbar.downloadCSV"
        />
      </template>
      <!-- prepare for printing -->
      <div
        class="btn btn-default btn-local"
        :class="
          this.mode == 'editor' || printStatus != 'idle' ? 'disabled' : ''
        "
        @click.stop.prevent="print"
        v-if="
          media == 'print' &&
          toolbar.preview &&
          (!$utils.isMobile() || toolbar.mobileNativePrintout)
        "
      >
        <i class="fa fa-print" v-if="printStatus == 'idle'"></i>
        <template v-else>
          <i class="fa fa-refresh fa-spin"></i>
          <span
            v-if="$store.getters.print"
            class="badge"
            style="margin-left: 5px; background-color: #666"
          >
            {{ printingPerc }}%
          </span>
        </template>
      </div>

      <!-- regular dashboard -->
      <div
        class="btn btn-default btn-local"
        :class="this.mode == 'editor' ? 'disabled' : ''"
        v-else-if="
          (media != 'print' && toolbar.preview) ||
          !$utils.isMobile() ||
          toolbar.mobileNativePrintout
        "
        @click.stop.prevent="preview"
        :title="$t('preview')"
      >
        <i class="hidden-xs fa fa-file-text-o" />
        <i
          class="hidden-sm hidden-md hidden-lg"
          :class="
            toolbar.mobileNativePrintout ? 'fa fa-print' : 'fa fa-file-text-o'
          "
        />
      </div>
      <!-- custom toolbar -->
      <div v-if="showCustomToolbar" class="custom-panel-toolbar">
        <slot name="toolbar"></slot>
      </div>
    </form>
    <NamedQuery
      v-for="item in namedQueryList"
      :key="item.name"
      :display="display"
      :queryName="item.name"
    />
  </div>
</template>

<script>
import Actions from "@/assets/dashboard/actions.json";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import EquipmentToolbarForm from "@/components/control-sidebar/property-editors/equipment-toolbar-form.vue";
import DownloadButton from "@/components/download-button.vue";
import Dropdown from "@/plugins/dropdown/dropdown.vue";
import NamedQuery from "@/components/statistics/named-query.vue";
import {defaultPanelOptions} from "@/components/control-sidebar/property-editors/detail-form-toolbar.vue";

export default {
  name: "EquipmentToolBarPanel",
  props: {
    display: {type: Object, required: false},
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    panel: {
      type: Object,
      required: true
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    DatetimeRangePicker,
    DownloadButton,
    Dropdown,
    NamedQuery
  },
  data() {
    return {
      selectedConnectorId: "",
      showAllConnectors: true,
      printStatus: "idle",
      dtIni: null,
      dtEnd: null,
      endsNow: false,
      showDateTimePicker: false,
      downloading: false,
      filterInitialized: false,
      selectedFilterValues: null
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    maxInterval() {
      return (this.contract.history_short_limit_to_query || 30) * 24;
    },
    historyInterval() {
      return this?.panelOptions?.history?.interval || 24;
    },
    media() {
      return this?.$route?.query?.media || "screen";
    },
    canEdit() {
      let user = this.$store.getters["user/loggedUser"] || null;
      return user ? !user.is_public_access : false;
    },
    connectorList() {
      return this.$store.getters["equipmentList"] || [];
    },
    toolbar() {
      let cfg = {
        ...defaultPanelOptions().toolbar.items,
        ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar?.items || {}))
      };
      let toolbar = {
        alwaysVisible: this?.panelOptions?.toolbar?.alwaysVisible
          ? true
          : false,
        defaultTab: this?.panelOptions?.toolbar?.defaultTab,
        calendarUIConfig:
          cfg.calendarInput.enabled && cfg.calendarButton.enabled
            ? "both"
            : cfg.calendarInput.enabled
            ? "input"
            : cfg.calendarButton.enabled
            ? "button"
            : "none",
        preview: cfg.preview.enabled || false,
        mobileNativePrintout: cfg.mobileNativePrintout.enabled || false,
        calendarShortCuts: cfg.calendarIntervalRange.enabled,
        dataFilter: cfg.dataFilter.enabled,
        largelongIntervalSelection: cfg.largelongIntervalSelection.enabled,
        downloadButton: cfg.downloadCSV.enabled || cfg.downloadXLS.enabled,
        downloadCSV: cfg.downloadCSV.enabled,
        downloadXLS: cfg.downloadXLS.enabled,
        chartTab: cfg.chartTab.enabled,
        reportTab: cfg.reportTab.enabled
      };
      return toolbar;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    printingPerc() {
      let p = Math.round(
        (this?.$store?.getters?.print && this?.$store?.getters?.print.total
          ? this.$store.getters.print.count / this.$store.getters.print.total
          : 0) * 100,
        0
      );
      return p;
    },
    dataFilterId() {
      if (!this.toolbar.dataFilter || !this?.panelOptions?.dataFilter?.data_id)
        return null;
      return (
        this.$store.getters["dashboard/dataList"].find(
          ({id}) =>
            parseInt(id) === parseInt(this.panelOptions.dataFilter.data_id)
        )?.id || null
      );
    },
    // filterValues() {
    //     if (this.isBusy) return [];
    //     return (
    //         (this.filterOptions.length &&
    //             this.$store.getters["history/filterValues"]) ||
    //         []
    //     );
    // },
    selectedFilterLabels() {
      return (this.dropdownOptions || [])
        .filter(({selected}) => selected)
        .map(({label}) => label);
    },
    // selectedFilterValues() {
    //     if (!this.filterInitialized) return null;
    //     return (this.dropdownOptions || [])
    //         .filter(({ selected }) => selected)
    //         .map(({ value }) => value);
    // },
    dropDownText() {
      if (!this.filterInitialized) return "";
      if (!this.selectedFilterLabels.length) return this.$tc("none", 1);
      return `${this.selectedFilterLabels[0]}${
        this.selectedFilterLabels.length > 1 ? "..." : ""
      }`;
    },
    dropDownHint() {
      if (!this.filterInitialized) return "";
      if (this.selectedFilterLabels.length) {
        return `${this.panelOptions.dataFilter.label || "values"} ${(
          this.selectedFilterValues || []
        )
          .map((i) => parseInt(i))
          .join(",")}`;
      } else {
        return `${this.$t("titles.filter_by")}: ${
          this.panelOptions.dataFilter.label
        }`;
      }
    },
    defaultFilterValues() {
      if (!this.dataFilterId) return [];
      let lst = this._defaultFilterValues;
      if (!lst.length) return [];
      lst = this.filterOptions.filter((value) =>
        lst.some((v) => Number(v) === Number(value))
      );
      return lst;
    },
    isBusy() {
      return this.$store.getters["history/busy"];
    },
    filterOptions() {
      if (!this.dataFilterId || this.isBusy) return null;
      return this.$store.getters["history/filterOptions"] || [];
    },
    dropdownOptions() {
      if (!this.dataFilterId || !this.filterInitialized || this.isBusy)
        return [];
      let selected = (this.selectedFilterValues || []).map((i) => `${i}`);
      let label = this?.panelOptions?.dataFilter?.label || this.$t("value");
      return this.filterOptions
        .map((value) => ({
          label: `${label} ${isNaN(Number(value)) ? value : parseFloat(value)}`,
          value: value,
          title: `${label} ${isNaN(Number(value)) ? value : parseFloat(value)}`,
          selected: selected.some((i) =>
            isNaN(Number(i)) ? value == i : parseFloat(i) == parseFloat(value)
          ),
          ix: isNaN(Number(value)) ? value : parseFloat(value)
        }))
        .sort((a, b) => (a.ix > b.ix ? 1 : a.ix < b.ix ? -1 : 0));
    },
    panelFilterKey() {
      return `${this.$options.name}-${this.screenId}`;
    },
    namedQueryList() {
      return this?.display?.namedQueries || [];
    }
  },
  watch: {
    toolbar() {
      if (this.mode != "editor") return;
      this.initCalendar();
    },
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "EquipmentToolbarForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: EquipmentToolbarForm
            });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
          }
        }
      },
      immediate: true
    },
    dataFilterId: {
      handler(n, o) {
        if (n && !o && !this.filterInitialized) {
          this.initDataFilter();
        }
      },
      immediate: true,
      deep: true
    },
    filterOptions: {
      handler(n, o) {
        if (!this.dataFilterId || this.filterInitialized) return;
        if (n && n.length) {
          this.selectedFilterValues = this.defaultFilterValues;
          this.$store.dispatch(
            "history/setFilterValues",
            this.defaultFilterValues
          );
          this.$nextTick(() => {
            this.filterInitialized = true;
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // BEGIN OF EVENT HANDLERs
    onDateRangePickerEvent(show) {
      this.$el.style.overflow = "visible";
    },
    onIntervalChanged(data) {
      this.dtIni = data.startDate;
      this.dtEnd = data.endDate;
      this.updateEndsNow();
      this.saveHistoryInterval();
      this.filterInitialized = false;
    },
    onUserRefresh() {
      if (!this.endsNow) return;
      this.initCalendar();
      // update calendar
    },
    onSelectConnector(id) {
      this.selectedConnectorId = id ? parseInt(id) : "";
      // TODO: Save at $store level (all panels will be sharing it)
    },
    onDownloading(opt) {
      this.downloading = opt;
      this.topMost();
    },
    // END OF EVENT HANDLERs
    updateEndsNow() {
      // important: can not be dynamic/reactive
      this.endsNow =
        moment().format("YYMMDDHHmm") == this.dtEnd.format("YYMMDDHHmm");
    },
    print() {
      if (this.mode == "editor") return;
      this.$root.$emit("dashboard:print");
    },
    preview() {
      if (this.mode == "editor") return;
      let actionType =
        this.toolbar.mobileNativePrintout && this.$utils.isMobile()
          ? "print:native"
          : "print:preview";

      let action = Actions.find(({type}) => type == actionType) || null;
      if (action) {
        action = JSON.parse(JSON.stringify(action));
        if (actionType == "print:preview") {
          action.options.screen_id.value = this.screenId;
        }
        this.$root.$emit("custom_action", {
          context: this,
          source: this,
          event: "click",
          connector: this.equipment,
          action: action
        });
      }
    },
    saveHistoryInterval() {
      if (this.mode == "editor") return;
      let interval = this.$store.getters["history/interval"] || null;
      if (
        !interval ||
        interval?.end != this.dtEnd ||
        interval?.start != this.dtIni
      ) {
        let entry = {
          start: this.dtIni,
          end: this.dtEnd
        };
        this.$store.dispatch("history/setInterval", entry);
      }
    },
    initCalendar() {
      let hours = this?.panelOptions?.history?.interval || 24;
      this.dtIni = moment().add(hours, "hours");
      this.dtEnd = moment();
      this.updateEndsNow();
      this.showDateTimePicker = false;
      this.$nextTick(() => {
        this.showDateTimePicker = true;
      });
    },
    updateCalendar() {
      let interval = this.$store.getters["history/interval"] || null;
      if (interval && interval.end && interval.start) {
        this.dtIni = interval.start;
        this.dtEnd = interval.end;
        this.showDateTimePicker = false;
        this.$nextTick(() => {
          this.showDateTimePicker = true;
        });
        return true;
      }
      return false;
    },
    setup() {
      if (this?.equipment?.id) {
        this.selectedConnectorId = this.equipment.id;
      }
      if (this.updateCalendar()) {
        if (this.media == "print") {
          return;
        }
      } else {
        this.initCalendar();
      }
      if (this?.panelOptions?.history?.startupUpdate) {
        this.$nextTick(() => {
          setTimeout(
            () => {
              this.$store.dispatch("history/setInterval", {
                start: this.dtIni,
                end: this.dtEnd
              });
            },
            250,
            this
          );
        });
      }
    },
    onPrinting(status) {
      this.printStatus = status;
    },
    showCustomToolbar() {
      return (this?.panel?.toolbar || []).length > 0;
    },
    setSideBar() {
      if (this.sidebar.name != "ImageForm") {
        this.$root.$emit("controlSidebar:setContent", EquipmentToolbarForm);
      }
    },
    topMost() {
      setTimeout(
        () => {
          this.$el.style.overflow = "visible";
          // this.$el.style["min-height"] = "auto";
        },
        100,
        this
      );
    },
    onFilterChanged(items) {
      if (!this.dataFilterId) return;
      var values = items.map(({value}) => value);
      this.selectedFilterValues = values;
    },
    dropdownApply(items) {
      if (!this.dataFilterId) return;
      var values = items.map(({value}) => value);
      this.selectedFilterValues = values;
      this.$store.dispatch("history/setFilterValues", values);
      let query = JSON.parse(window.localStorage.getItem("query") || "{}");
      query.panels = query.panels || {};
      query.panels[this.panelFilterKey] =
        query.panels[this.panelFilterKey] || {};
      query.panels[this.panelFilterKey].filter =
        query.panels[this.panelFilterKey].filter || {};
      query.panels[this.panelFilterKey].filter[this.dataFilterId] = values;
      window.localStorage.setItem("query", JSON.stringify(query));
    },
    initDataFilter() {
      if (!this.dataFilterId) return;
      // restore
      let query = JSON.parse(window.localStorage.getItem("query") || "{}");
      // non reactive
      this._defaultFilterValues =
        ((query?.panels || {})[this.panelFilterKey]?.filter || {})[
          this.dataFilterId
        ] || [];
      if (!this._defaultFilterValues.length) {
        this._defaultFilterValues =
          (this?.panelOptions?.dataFilter.value || "").split(",") || [];
      }
      this.$store.dispatch("history/setFilter", {
        data_id: this.dataFilterId,
        isRequired: this?.panelOptions?.dataFilter?.isRequired,
        values: []
      });
    }
  },
  mounted() {
    this.$root.$emit("dashboard:enable_pooling", false);
    this.$root.$on("dashboard:printing", this.onPrinting);
    this.setup();
    if (this.isEditing) {
      this.setSideBar();
    } else {
      // this.initDataFilter();
    }
    this.$nextTick(this.topMost);
  },
  created() {
    this.$root.$on("refreshPage", this.onUserRefresh);
    if (this.mode == "viewer") {
      this.$root.$emit(
        "dashboard:pageSettings",
        this?.panelOptions?.pageSettings || null
      );
      // if (!this.isEditing) {
      //     this.initDataFilter();
      // }
    }
  },
  // beforeCreate() {
  //     if (this.mode == "viewer") {
  //         if (!this.isEditing) {
  //             this.initDataFilter();
  //         }
  //     }
  // },
  beforeDestroy() {
    this.$root.$off("refreshPage", this.onUserRefresh);
    this.$root.$emit("dashboard:enable_pooling", true);
    this.$root.$off("dashboard:printing", this.onPrinting);
  }
};
</script>

<style scoped>
.panel-toolbar {
  display: block;
  overflow: visible;
  white-space: nowrap;
  padding: 0;
  border-radius: 3px;
}

form {
  padding: 5px 0;
  margin: 0;
}

.panel-toolbar > form > div {
  margin: 0 10px;
  vertical-align: middle;
  display: inline-block;
}

.custom-panel-toolbar:deep(.btn-local) {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  padding: 4px 6px;
  height: inherit;
  margin-top: 2px;
  margin-left: 0px;
}

.input-group-addon .btn-local {
  background-color: white;
}

.form-group {
  background-color: white;
}

.btn-all {
  border: 0;
  padding: 8px 14px;
}

.btn-all > i {
  min-width: 18px;
}

.btn-all > span {
  margin-left: 5px;
}

.vselector {
  display: inline-block;
  padding: 0;
  height: inherit;
  font-size: 11pt;
  min-width: 250px;
  border: 0;
}

input.dateranger {
  padding: 0;
  min-width: 200px;
  text-align: center;
  letter-spacing: -0.03em;
}

@media print {
  .panel-toolbar {
    display: none;
  }
}

.content-panel-toolbar {
  vertical-align: middle;
}

.custom-panel-toolbar:deep(.btn-local) {
  background-color: transparent;
  border-color: transparent;
}

.hsy-dropdown.btn-filter::v-deep > .btn {
  min-width: 105px;
}
.hsy-dropdown.btn-primary::v-deep {
  border-radius: 3px;
}
.hsy-dropdown.btn-primary::v-deep > .btn {
  background-color: inherit;
  color: inherit;
  border-color: inherit;
}
</style>